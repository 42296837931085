<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="attributes"
          :items="attributes"
          :itemHeader="attributeItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      attributes: [],
      attributeItemHeader: [
        { text: "id", value: "id" },
        { text: "Title", value: "english_title" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/attributes?fields=id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,attribute_image.id"
        );
        this.attributes = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
